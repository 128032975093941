import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Page not found</title>
        <meta name='description' content='404 Page Not Found error page' />
      </Helmet>

      <title>Page Not found</title>

      <div className="page-content rich-text-page not-found-page">
        <h1>Page not found</h1>
        <p>
          Sorry we couldn’t find what you were looking for. Please try returning to our <Link to="/">Home Page</Link> and trying again.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
